import { createSlice } from "@reduxjs/toolkit"

import storage from "../storage"

const randomPickerSlice = createSlice({
  name: `randomPicker`,
  initialState: {
    listItems: [],
    isAddMultiItems: false,
    autoIncrement: 0,
    speed: `normal`,
  },
  reducers: {
    setListItems: (state, action) => {
      state.listItems = action.payload
    },
    setIsAddMultiItems: (state, action) => {
      state.isAddMultiItems = action.payload
    },
    setAutoIncrement: (state, action) => {
      state.autoIncrement = action.payload
    },
    setSpeed: (state, action) => {
      state.speed = action.payload
    },
    setZero: () => randomPickerSlice.getInitialState(),
  },
})

const randomPickerReducer = randomPickerSlice.reducer
const randomPickerPersistConfig = {
  key: `basuke436_random-picker`,
  storage: storage,
  whitelist: [`listItems`, `isAddMultiItems`, `autoIncrement`, `speed`],
}

export const {
  setListItems,
  setIsAddMultiItems,
  setAutoIncrement,
  setSpeed,
  setZero,
} = randomPickerSlice.actions
export { randomPickerReducer, randomPickerPersistConfig }

import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import getSiteColor from "../functions/get-site-color"
import hexToRgbaColor from "../functions/hex-to-rgba-color"

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  position: relative;
  background-color: ${({ theme, color }) =>
    hexToRgbaColor(
      getSiteColor(color, theme === `light` ? `dark` : `light`),
      theme === `light` ? 0.4 : 0.2
    )};
  transition: background-color 0.3s;
  opacity: 0;
`

const FooterRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 2rem 0;
  transition: 0.3s;

  @media (max-width: 767px) {
    padding: 1.5rem 0;
  }
`

const Col = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const Text = styled.span`
  color: ${({ theme }) =>
    theme === `light` ? `rgba(0, 0, 0, 0.75)` : `rgba(255, 255, 255, 0.75)`};
  font-weight: bold;
  transition: 0.3s;

  @media (max-width: 767px) {
    &.desktop {
      display: none;
    }
  }
`

const Footer = () => {
  const { theme, siteColor } = useSelector(({ mainReducer }) => mainReducer)

  return (
    <FooterContainer
      className="animate__animated animate__fadeIn animate__delay-0_5s"
      theme={theme}
      color={siteColor}
    >
      <FooterRow theme={theme}>
        <Col>
          <Text theme={theme}>© 2024</Text>
          <Text theme={theme} className="desktop">
            |
          </Text>
          <Text theme={theme}>Lt. Apirak Suwanyotee</Text>
        </Col>
      </FooterRow>
    </FooterContainer>
  )
}

export default Footer
